<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="mb-3">
              <h4 class="page-title">Trainer Profile</h4>
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="row row-cols-1 row-cols-md-3 g-4">
              <div
                class="col border rounded ms-3 shadow-lg"
                v-for="(item, index) in filterEmployee"
                :key="index"
              >
                <div class="card h-100">
                  <div class="float-end mt-2">
                    <img
                      src="../../assets/images/umg_logo.jpg"
                      class="logo-img float-end"
                      alt="..."
                    />
                  </div>
                  <img
                    :src="item.image_url"
                    class="card-img-top mx-auto"
                    alt="..."
                  />
                  <div class="card-body">
                    <h4 class="card-title text-center text-uppercase">
                      {{ item.name }}
                    </h4>
                    <p class="fs-5 text-center">{{ item.position_name }}</p>
                    <p class="fs-6 text-center">{{ item.department_name }}</p>
                    <div class="text-center mt-3">
                      <router-link
                        :to="{
                          name: 'trainer-profile-detail',
                          params: { id: item.id },
                        }"
                      >
                        <button
                          type="button"
                          class="btn btn-blue waves-effect waves-light"
                        >
                          See Detail
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

//Mixins
import userHasPermissions from "../../mixins/userHasPermissions";

export default {
  components: {},
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      trainers: [],
      filterEmployee: [],
      employees: this.$store.getters["odoo/getAllEmployees"],
      loading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    async getAllTrainers() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrl}admin/v1/trainers`)
        .then((response) => {
          // this.trainers = response.data.data;
          this.trainers = response.data.data.filter(function (val) {
            //for active trainers
            if (val.status == 1) {
              return {
                id: val.user.id,
                employee_id: val.user.employee_id,
                name: val.user.name,
                email: val.user.email,
                media: val.media,
              };
            }
          });

          this.trainers.map((trainer) => {
            this.employees.map((employee) => {
              if (employee.emp_id == trainer.employee_id) {
                let data = {
                  id: trainer.id,
                  image_url: trainer.media.image_url,
                  emp_id: employee.emp_id,
                  name: employee.name,
                  position_name: employee.position_level[0].name,
                  department_name: employee.department[0].name,
                };
                this.filterEmployee.push(data);
              }
            });
          });
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Something Went Wrong!");
        });
    },
  },
  created() {
    this.getAllTrainers();
  },
};
</script>

<style>
.row-cols-md-3 > * {
  flex: 0 0 auto;
  width: 30%;
}
</style>
<style scoped>
.card-img-top {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 1px solid #ddd;
}

.logo-img {
  width: 50px;
  height: 50px;
}
</style>
